import React from 'react'
import * as S from './styles'
import data from './assets/data.json'
import slugify from 'slugify'
import usePageQuery from '../../pageQuery'
import Img from 'gatsby-image'

interface IData {
  title: string;
  beneficio: string;
  description: string;
}

const ComoAcumularPontosComOCartao = () => {
  const cards: IData[] = data
  const imageData = usePageQuery()
  return (
    <S.Section
      id='chegou-o-programa-de-pontos-pj'
      className='bg-grayscale--100'
    >
      <div className='container'>
        <div className='row align-items-md-center'>
          <div className='col-12 col-md-5 col-lg-5 offset-lg-1 offset-xl-0 order-md-last mb-4 mb-md-0'>
            <Img fluid={imageData.bannerComoAcumularPontosComOCartaoDeCredito.fluid} alt='Cartão de Crédito Inter Empresas frente e verso.' />
          </div>
          <div className='col-12 col-md-7 col-lg-6 col-xl-7'>
            <h2 className='fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 fw-600 text-grayscale--500 mb-4'>Como acumular pontos <span className='d-xl-block'>com o cartão de crédito</span> Inter Empresas?</h2>
            <p className='fs-14 lh-17 fs-lg-16 lh-lg-20 fs-xl-18 lh-xl-22 text-grayscale--400 mb-0'>Com o Inter Loop, os cartões PJ e MEI acumulam pontos a cada <span className='d-xl-block'>compra efetuada no crédito</span></p>
            <S.Cards>
              {cards.map((card: IData) => (
                <S.Card key={slugify(card.title)} className='d-flex flex-column justify-content-between'>
                  <div className=''>
                    <h3 dangerouslySetInnerHTML={{ __html: card.title }} className='fs-14 lh-17 fs-md-16 lh-md-20 fs-xl-18 lh-xl-22 fw-700 text-grayscale--500 mb-2' />
                    <p dangerouslySetInnerHTML={{ __html: card.beneficio }} className='fs-12 lh-15 fs-md-14 lh-md-17 fs-lg-16 lh-lg-20 fs-xl-18 lh-xl-22 fw-700 text-orange--extra mb-0' />
                  </div>
                  <div className=''>
                    <p className='fs-10 lh-12 fs-xl-12 lh-xl-15 fw-400 text-grayscale--400 mb-0'>{card.description}</p>
                  </div>
                </S.Card>
              ))}
            </S.Cards>
          </div>
        </div>
      </div>
    </S.Section>
  )
}

export default ComoAcumularPontosComOCartao
