import styled from 'styled-components'
import { green, white } from 'src/styles/colors'
import { device } from 'src/styles/breakpoints'

import BackgroundMD from '../../assets/images/background-hero-loop-pj-md.png'
import BackgroundLG from '../../assets/images/background-hero-loop-pj-lg.png'
import BackgroundXL from '../../assets/images/background-hero-loop-pj-xl.png'

export const Section = styled.section`
  background-color: ${green[500]};
  padding-top: 40px;
  padding-bottom: 40px;

  @media ${device.tablet} {
    background-image: url(${BackgroundMD});
    background-color: #00625B;
    background-repeat: no-repeat;
    background-position: bottom right;
    height: 424px;
    padding-top: 0;
    padding-bottom: 0;
  }
  @media ${device.desktopLG} {
    background-image: url(${BackgroundLG});
    height: 482px;
  }
  @media ${device.desktopXL} {
    background-image: url(${BackgroundXL});
    height: 640px;
  }

  .gatsby-image-wrapper {
    width: 153px;
    @media ${device.tablet} {
      width: 185px;
    }
    @media ${device.desktopLG} {
      width: 223px;
    }
    @media ${device.desktopXL} {
      width: 311px;
    }
  }
`

export const Link = styled.a`
  height: 48px;
  width: 100%;
  background-color: ${white};
  color: ${green[500]};
  border-radius: 8px;
  padding: 16px 24px;
  font-weight: 700;
  margin-top: 32px;
  margin-bottom: 24px;

  @media ${device.tablet} {
    margin-bottom: 8px;
  }
  @media ${device.desktopLG} {
    margin-bottom: 16px;
  }
  @media ${device.desktopXL} {
    margin-bottom: 24px;
  }
  @media ${device.desktopXL} {
    margin-top: 40px;
  }
`

export const Button = styled.button`
  height: 48px;
  width: 100%;
  background-color: ${green[500]};
  color: ${white};
  border-radius: 8px;
  padding: 16px 24px;
  font-weight: 700;
  border: 1px solid #E5F2F1;
`
