import styled from 'styled-components'
import { green } from 'src/styles/colors'

// Background
import BackgroundSM from '../../assets/images/background-sm-use-seu-cartao-inter-mastercard.png'
import BackgroundMD from '../../assets/images/background-md-use-seu-cartao-inter-mastercard.png'
import BackgroundLG from '../../assets/images/background-lg-use-seu-cartao-inter-mastercard.png'
import BackgroundXL from '../../assets/images/background-xl-use-seu-cartao-inter-mastercard.png'

import { device } from 'src/styles/breakpoints'

export const Section = styled.section`
  padding: 40px 0;
  @media ${device.desktopLG} {
    padding: 64px 0;
  }
  @media ${device.desktopXL} {
    padding: 96px 0;
  }

  height: 930px;
  background-position-x: left;
  background-position-y: 40px;
  background-repeat: no-repeat;
  background-color: ${green[500]};
  background-image: url(${BackgroundSM});

  h1 {
    margin-bottom: 20px;
  }

  @media ${device.tablet} {
    height: 541px;
    background-position-x: left;
    background-position-y: 185px;
    background-image: url(${BackgroundMD});
    h1 {
      margin-bottom: 36px;
    }
  }
  @media ${device.desktopLG} {
    height: 600px;
    background-image: url(${BackgroundLG});
  }
  @media ${device.desktopXL} {
    height: 773px;
    background-position-y: 265px;
    background-image: url(${BackgroundXL});
  }
`

export const Card = styled.div`
  margin-bottom: 24px;
  @media ${device.tablet} {
    margin-bottom: 40px;
  }
`
