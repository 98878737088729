import React from 'react'
import * as S from './styles'

// Icons
import Invoice from '@interco/icons/build-v4/orangeds/MD/invoice'
import ShoppingBag from '@interco/icons/build-v4/orangeds/MD/shopping-bag'
import Cashback from '@interco/icons/build-v4/orangeds/MD/cashback'

import data from './assets/data.json'
import { orange } from 'src/styles/colors'

import { IconProps } from 'src/components/Layout/Footer/Footer.interface'

interface IData {
  icon: string;
  title: string;
  description: string;
}

const UseSeuCartaoInterMastercardMicrobusiness = () => {
  const icons: IconProps = {
    invoice: <Invoice width='26px' height='26px' color={orange.extra} />,
    shoppingBag: <ShoppingBag width='26px' height='26px' color={orange.extra} />,
    cashback: <Cashback width='26px' height='26px' color={orange.extra} />,
  }

  const beneficts: IData[] = data

  return (
    <S.Section
      id='chegou-o-programa-de-pontos-pj'
      className='d-flex align-items-end align-items-md-start'
      role='img'
      aria-label='Smartphone com o Super App Inter Empresas aberto. Ao lado, um cartão de crédito Inter Empresas.'
    >
      <div className='container'>
        <div className='row'>
          <div className='col-12 text-md-center'>
            <h2 className='fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 fw-600 text-white'>Use seu cartão Inter Mastercard Microbusiness, <span className='d-xl-block'>acumule pontos e aproveite nossos benefícios para</span> empresas</h2>
          </div>
          <div className='col-md-6 offset-md-6 mt-xl-5'>
            <div>
              {beneficts.map((benefict: IData) => (
                <S.Card key={benefict.icon}>
                  <div className='d-flex align-items-center'>
                    <div className='mr-2'>
                      {icons[benefict.icon]}
                    </div>
                    <div>
                      <h3 dangerouslySetInnerHTML={{ __html: benefict.title }} className='fs-16 lh-20 fs-lg-20 lh-lg-25 fs-xl-24 lh-xl-30 fw-600 text-white mb-0' />
                    </div>
                  </div>
                  <div className='mt-2'>
                    <p dangerouslySetInnerHTML={{ __html: benefict.description }} className='fs-14 lh-17 fs-lg-16 lh-lg-20 fs-xl-18 lh-xl-22 fw-400 text-white' />
                  </div>
                </S.Card>
              ))}
            </div>
          </div>
        </div>
      </div>
    </S.Section>
  )
}

export default UseSeuCartaoInterMastercardMicrobusiness
