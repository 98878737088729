import { device } from 'src/styles/breakpoints'
import { grayscale } from 'src/styles/colors'
import styled from 'styled-components'

export const Section = styled.section`
  padding: 40px 0;
  @media ${device.desktopLG} {
    padding: 64px 0;
  }
  @media ${device.desktopXL} {
    padding: 96px 0;
  }

  h2 {
    margin-bottom: 16px;
    @media ${device.desktopLG} {
      margin-bottom: 24px;
    }
    @media ${device.desktopXL} {
      margin-bottom: 40px;
    }
  }
`

export const Cards = styled.div`
  @media ${device.tablet} {
    margin-top: 40px;
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
  }

  div:last-child {
    border-bottom: none;
    padding-bottom: 0;
  }
`

export const Card = styled.div`
  border-bottom: 1px solid ${grayscale[200]};
  padding-bottom: 24px;
  padding-top: 24px;

  @media ${device.tablet} {
    border: 1px solid ${grayscale[200]};
    border-bottom: 1px solid ${grayscale[200]} !important;
    border-radius: 8px;
    padding: 24px 20px;

    width: 336px;
    height: 226px;
  }

  @media ${device.desktopLG} {
    width: 216px;
    height: 360px;
  }

  @media ${device.desktopXL} {
    width: 264px;
    height: 342px;
  }
`
