import { device } from 'src/styles/breakpoints'
import { grayscale } from 'src/styles/colors'
import styled from 'styled-components'

export const Section = styled.section`
  padding: 40px 0;

  @media ${device.desktopLG} {
    padding: 64px 0;
  }
  @media ${device.desktopXL} {
    padding: 96px 0;
  }

  h2 {
    margin-bottom: 16px;
    @media ${device.desktopLG} {
      margin-bottom: 24px;
    }
    @media ${device.desktopXL} {
      margin-bottom: 40px;
    }
  }
`

export const Cards = styled.div`
  margin-top: 32px;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;

  @media ${device.desktopLG} {
    gap: 32px;
  }

  @media ${device.desktopXL} {
    gap: 24px;
    margin-top: 40px;
  }
`

export const Card = styled.div`
    border: 1px solid ${grayscale[200]};
    border-radius: 8px;
    padding: 14px;

    width: 148px;
    height: 220px;

  @media ${device.tablet} {
    padding: 16px;
    width: 176px;
    height: 220px;
  }

  @media ${device.desktopLG} {
    width: 212px;
    height: 243px;
    padding: 24px;
  }

  @media ${device.desktopXL} {
    width: 290px;
    height: 221px;
  }
`
