import { useStaticQuery, graphql } from 'gatsby'

const PageQuery = () => {
  const pageQuery = useStaticQuery(graphql`
    query {
      logoLoopPJ: imageSharp(fluid: {originalName: { regex: "/logo-loop-pj/" }}) {
        fluid(maxWidth: 311, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      bannerComoAcumularPontosComOCartaoDeCredito: imageSharp(fluid: {originalName: { regex: "/banner-como-acumular-pontos-com-o-cartao/" }}) {
        fluid(maxWidth: 457, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
  `)

  return pageQuery
}

export default PageQuery
