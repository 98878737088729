import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'

export const Section = styled.section`
  padding: 40px 0;
  @media ${device.desktopLG} {
    padding: 64px 0;
  }
  @media ${device.desktopXL} {
    padding: 96px 0;
  }

  a {
    margin-top: 32px;
    height: 48px !important;
    @media ${device.tablet} {
      width: 291px !important;
    }
    @media ${device.desktopLG} {
      width: 346px !important;
    }
    @media ${device.desktopXL} {
      margin-top: 40px;
      width: 457px !important;
    }
  }
`
