import React from 'react'
import * as S from './styles'

// Icons
import ShoppingBag from '@interco/icons/build-v4/orangeds/MD/shopping-bag'
import Show from '@interco/icons/build-v4/orangeds/MD/show'
import Agenda from '@interco/icons/build-v4/orangeds/MD/agenda'

import data from './assets/data.json'
import FourPointedStarIcon from '../../assets/images/icon-four-pointed-star.png'
import { orange } from 'src/styles/colors'

import { IconProps } from 'src/components/Layout/Footer/Footer.interface'

interface IData {
  icon: string;
  title: string;
  description: string;
}

const SeuProgramaDeFidelidadeParaEmpresas = () => {
  const icons: IconProps = {
    fourPointedStar: <img src={FourPointedStarIcon} width='32px' height='32px' alt='icone four pointed start' />,
    shoppingBag: <ShoppingBag width='32px' height='32px' color={orange.extra} />,
    show: <Show width='32px' height='32px' color={orange.extra} />,
    agenda: <Agenda width='32px' height='32px' color={orange.extra} />,
  }

  const cards: IData[] = data

  return (
    <S.Section
      id='seu-programa-de-pontos'
    >
      <div className='container'>
        <div className='row'>
          <div className='col-12 text-md-center'>
            <h2 className='fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-lg-50 fw-600 text-grayscale--500 font-sora'>No Inter Loop, seu programa de pontos para empresas é sinônimo de liberdade de escolha</h2>
            <p className='fs-14 lh-17 fs-lg-16 lh-lg-20 fs-xl-18 lh-xl-22 fw-400 text-grayscale--400 mb-0'>Muito mais que cashback: simplifique os benefícios do cartão de crédito do seu negócio</p>
          </div>
          <div className='col-12'>
            <S.Cards className='justify-content-md-center'>
              {cards.map((card: IData) => (
                <S.Card key={card.icon} className='card-interloop'>
                  <div className='d-flex align-items-center d-md-block'>
                    <div className='mr-3 mr-md-0 mb-md-3'>
                      {icons[card.icon]}
                    </div>
                    <h3 dangerouslySetInnerHTML={{ __html: card.title }} className='fs-16 lh-20 fs-lg-20 lh-lg-25 fw-600 text-grayscale--500 mb-0 font-sora' />
                  </div>
                  <div className='mt-3'>
                    <p className='fs-14 lh-17 fs-lg-16 lh-lg-20 fw-400 text-grayscale--400 mb-0' dangerouslySetInnerHTML={{ __html: card.description }} />
                  </div>
                </S.Card>
              ))}
            </S.Cards>
          </div>
        </div>
      </div>
    </S.Section>
  )
}

export default SeuProgramaDeFidelidadeParaEmpresas
