import React from 'react'
import Img from 'gatsby-image'
import usePageQuery from '../../pageQuery'
import ScrollTo from 'src/components/ScrollTo'

import * as S from './styles'
import { ISendDataLayerProps } from '../../types'

const HeroLoopPJ = ({ sendDatalayerEvent }: ISendDataLayerProps) => {
  const data = usePageQuery()
  const ctaUrl = 'https://conta-digital-pj.bancointer.com.br/login?&gaid=GA1.3.2065579558.1674475077&_ga=2.203481110.764229343.1684162555-2065579558.1674475077'
  return (
    <S.Section
      id='chegou-o-programa-de-pontos-pj'
      className='d-md-flex align-items-md-center'
      role='img'
      aria-label='Empreendedor, cliente Inter Empresas, segurando seu cartão de crédito empresarial. Ao fundo, ícones representando moedas e um troféu.'
    >
      <div className='container'>
        <div className='row align-items-md-center'>
          <div className='col-12 col-md-6 col-xl-5'>
            <Img fluid={data.logoLoopPJ.fluid} alt='Logo loop' className='mb-2' />
            <h1 className='fs-24 lh-30 fs-md-20 lh-md-25 fs-lg-24 lh-lg-30 fs-xl-32 lh-xl-40 fw-400 text-white mb-3 fw-400 font-sora'>Chegou o programa de pontos para empresas que seu negócio precisa</h1>
            <p className='fs-14 lh-17 fs-lg-16 lh-lg-20 fs-xl-18 lh-xl-22 fw-400 text-white mb-0'>Agora seu cartão acumula pontos e sua empresa tem flexibilidade para escolher os benefícios que mais combinam com o seu negócio.</p>
            <S.Link
              target='_blank'
              rel='noreferrer'
              href={ctaUrl}
              className='fs-14 lh-17 fw-700 d-flex justify-content-center'
              onClick={() => {
                sendDatalayerEvent({
                  section: 'dobra_01',
                  element_action: 'click button',
                  element_name: 'Abrir conta PJ gratuita',
                  section_name: 'Chegou o programa de pontos para empresas que seu negócio precisa',
                  redirect_url: ctaUrl,
                })
              }}
            >
              Abrir conta PJ gratuita
            </S.Link>
            <ScrollTo
              to='#seu-programa-de-pontos'
              section='dobra_01'
              sectionName='Chegou o programa de pontos para empresas que seu negócio precisa'
              elementName='Quero ganhar pontos'
            >
              <S.Button className='fs-14 lh-17 fw-700 d-flex justify-content-center'>
                Quero ganhar pontos
              </S.Button>
            </ScrollTo>
          </div>
        </div>
      </div>
    </S.Section>
  )
}

export default HeroLoopPJ
